import { ExclusiveDutchOrder, createExclusiveDutchOrderTrade } from './chunk-LKGFR7CY.mjs';
import { verifyTestnet } from '@plexswap/chains';
import { TradeType } from '@plexswap/sdk-core';
import { AetherRouter } from '@plexswap/gateway-guardians';
import { PoolType } from '@plexswap/gateway-guardians/Ananke';

var zeroAddress = "0x0000000000000000000000000000000000000000";
function getCurrencyKey(currencyParams) {
  if (!currencyParams) {
    return void 0;
  }
  if ("isNative" in currencyParams && currencyParams.isNative === true) {
    return `${currencyParams.chainId}:${zeroAddress}`;
  }
  const { chainId, address } = currencyParams;
  return `${chainId}:${address.toLowerCase()}`;
}
function getCurrencyListKey(currencyListParams) {
  if (!currencyListParams) {
    return void 0;
  }
  const currencyKeys = currencyListParams.map(getCurrencyKey).filter((key) => !!key);
  const uniqueKeys = [...new Set(currencyKeys)];
  return uniqueKeys.join(",");
}
function getRequestUrl(params) {
  if (!params) {
    return void 0;
  }
  const infoList = Array.isArray(params) ? params : [params];
  const key = getCurrencyListKey(infoList.filter((c) => !verifyTestnet(c.chainId)));
  if (!key) {
    return void 0;
  }
  const encodedKey = encodeURIComponent(key);
  return `${PRICE_API}/prices/list/${encodedKey}`;
}
async function getCurrencyUsdPrice(currencyParams, options) {
  if (!currencyParams || verifyTestnet(currencyParams.chainId)) {
    return 0;
  }
  const prices = await getCurrencyListUsdPrice([currencyParams], options);
  const key = getCurrencyKey(currencyParams);
  return (key && prices[key]) ?? 0;
}
async function getCurrencyListUsdPrice(currencyListParams, options) {
  const requestUrl = getRequestUrl(currencyListParams);
  if (!requestUrl || !currencyListParams) {
    throw new Error(`Invalid request for currency prices, request url: ${requestUrl}`);
  }
  const res = await fetch(requestUrl, options);
  const data = await res.json();
  return data;
}

// Valorus/types/api.ts
var ResponseType = /* @__PURE__ */ ((ResponseType2) => {
  ResponseType2["PRICE_RESPONSE"] = "PRICE_RESPONSE";
  ResponseType2["MM_PRICE_RESPONSE"] = "MM_PRICE_RESPONSE";
  ResponseType2["DUTCH_LIMIT_RESPONSE"] = "DUTCH_LIMIT_RESPONSE";
  ResponseType2["AMM_PRICE_RESPONSE"] = "AMM_PRICE_RESPONSE";
  ResponseType2["ERROR"] = "ERROR";
  return ResponseType2;
})(ResponseType || {});

// Valorus/types/orderType.ts
var OrderType = /* @__PURE__ */ ((OrderType2) => {
  OrderType2["DUTCH_LIMIT"] = "DUTCH_LIMIT";
  OrderType2["PCS_CLASSIC"] = "PCS_CLASSIC";
  return OrderType2;
})(OrderType || {});
function getPoolType(type) {
  return PoolType[type];
}
function getPoolTypeKey(poolType) {
  for (const [key, value] of Object.entries(PoolType)) {
    if (value === poolType) {
      return key;
    }
  }
  throw new Error(`Invalid pool type: ${poolType}`);
}
function getTradeTypeKey(tradeType) {
  for (const [key, value] of Object.entries(TradeType)) {
    if (value === tradeType) {
      return key;
    }
  }
  throw new Error(`Invalid trade type: ${tradeType}`);
}

// Valorus/orderPriceApiParsers.ts
var getCurrencyIdentifier = (currency) => currency.isNative ? zeroAddress : currency.wrapped.address;
function getRequestBody({ amount, quoteCurrency, tradeType, amm, x, slippage }) {
  const [currencyIn, currencyOut] = tradeType === TradeType.EXACT_INPUT ? [amount.currency, quoteCurrency] : [quoteCurrency, amount.currency];
  const configs = [];
  if (amm) {
    configs.push({
      protocols: amm.poolTypes?.map(getPoolTypeKey) || ["CORE", "EXTENDED", "STABLE"],
      routingType: "PCS_CLASSIC" /* PCS_CLASSIC */,
      gasPriceWei: amm.gasPriceWei?.toString(),
      maxHops: amm.maxHops,
      maxSplits: amm.maxSplits
    });
  }
  if (x) {
    configs.push({
      routingType: "DUTCH_LIMIT" /* DUTCH_LIMIT */,
      ...x
    });
  }
  return {
    amount: amount.quotient.toString(),
    type: getTradeTypeKey(tradeType),
    tokenInChainId: currencyIn.chainId,
    tokenOutChainId: currencyOut.chainId,
    slippageTolerance: slippage?.toFixed(2),
    tokenIn: getCurrencyIdentifier(currencyIn),
    tokenOut: getCurrencyIdentifier(currencyOut),
    configs
  };
}
function parseQuoteResponse(res, {
  chainId,
  currencyOut,
  currencyIn,
  tradeType
}) {
  if (res.messageType === "ERROR" /* ERROR */) {
    throw new Error(res.message.error);
  }
  const { bestOrder, allPossibleOrders } = res.message;
  if (bestOrder.type === "PCS_CLASSIC" /* PCS_CLASSIC */) {
    return {
      type: "PCS_CLASSIC" /* PCS_CLASSIC */,
      trade: AetherRouter.Transformer.parseTrade(chainId, bestOrder.order)
    };
  }
  if (bestOrder.type === "DUTCH_LIMIT" /* DUTCH_LIMIT */) {
    const order = ExclusiveDutchOrder.fromJSON(bestOrder.order.orderInfo, chainId);
    const otherAmmTrade = allPossibleOrders.find((o) => o.type === "PCS_CLASSIC" /* PCS_CLASSIC */);
    return {
      type: "DUTCH_LIMIT" /* DUTCH_LIMIT */,
      ammTrade: otherAmmTrade ? AetherRouter.Transformer.parseTrade(chainId, otherAmmTrade.order) : void 0,
      trade: createExclusiveDutchOrderTrade({
        currencyIn,
        currenciesOut: [currencyOut],
        orderInfo: order.info,
        tradeType
      })
    };
  }
  throw new Error(`Unknown order type`);
}
function parseAMMPriceResponse(chainId, res) {
  const {
    message: { gasUseEstimateUSD, ...rest }
  } = res;
  const trade = AetherRouter.Transformer.parseTrade(chainId, rest);
  return {
    ...trade,
    gasUseEstimateUSD: Number(gasUseEstimateUSD)
  };
}

// Valorus/index.ts
var PRICE_API = "https://prices-api.plexfinance.us";

export { OrderType, PRICE_API, ResponseType, getCurrencyKey, getCurrencyListKey, getCurrencyListUsdPrice, getCurrencyUsdPrice, getPoolType, getPoolTypeKey, getRequestBody, parseAMMPriceResponse, parseQuoteResponse, zeroAddress };
